<template>
  <!--begin::Form-->
  <Form
    id="kt_organization_form"
    class="form fv-plugins-bootstrap5 fv-plugins-framework"
    novalidate="novalidate"
    @submit="save"
    :validation-schema="submitOrganization"
  >
    <!--begin:: Name-->
    <div class="row mb-10">
      <!--begin::Col-->
      <div class="col-md-12 fv-row">
        <!--begin::Row-->
        <div class="row fv-row">
          <!--begin::Col-->
          <div class="col-12">
            <!--begin::Label-->
            <label
              class="d-flex align-items-center fs-6 fw-bold form-label mb-2"
            >
              <span class="required">{{ translate("organization") }}</span>
              <i
                class="fas fa-exclamation-circle ms-2 fs-7"
                data-bs-toggle="tooltip"
                title="Specify a card holder's name"
              ></i>
            </label>
            <!--end::Label-->

            <Field
              :disabled="!isEditionEnabled"
              v-model="form.name"
              autofocus
              type="text"
              class="form-control form-control-solid"
              placeholder=""
              name="nameOnCard"
              id="organization-name"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="nameOnCard" />
              </div>
            </div>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Col-->
    </div>
    <!--end:: Name-->

    <!--begin:: Industry-->
    <div class="row mb-10">
      <!--begin::Col-->
      <div class="col-md-12 fv-row">
        <!--begin::Row-->
        <div class="row fv-row">
          <!--begin::Col-->
          <div class="col-12">
            <!--begin::Label-->
            <label
              class="d-flex align-items-center fs-6 fw-bold form-label mb-2"
            >
              <span class="required">{{ translate("industry") }}</span>
              <i
                class="fas fa-exclamation-circle ms-2 fs-7"
                data-bs-toggle="tooltip"
                title="Specify a card holder's name"
              ></i>
            </label>
            <!--end::Label-->

            <!-- Industry select -->
            <Field
              :disabled="!isEditionEnabled"
              v-model="form.industry"
              id="industry_selector"
              name="industry"
              class="form-select form-select-solid"
              data-control="select2"
              data-hide-search="true"
              data-placeholder="Month"
              as="select"
            >
              <option></option>
              <template
                v-for="(industry, industryIndex) in industries"
                :key="industryIndex"
              >
                <option :value="industry.key">
                  {{ translate(`industria.${industry.key}`) }}
                </option>
              </template>
            </Field>
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="industry" />
              </div>
            </div>
            <!-- end:: Industry select -->
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Col-->
    </div>
    <!--end:: Industry-->

    <!--begin::Input group-->
    <div class="row mb-10">
      <!--begin::Col-->
      <div class="col-md-12 fv-row">
        <!--begin::Row-->
        <div class="row fv-row">
          <!--begin::Col-->
          <div class="col-6">
            <!--begin::Label-->
            <label class="required fs-6 fw-bold form-label mb-2">{{
              translate("employees")
            }}</label>
            <!--end::Label-->

            <!-- @todo -->
            <Field
              :disabled="!isEditionEnabled"
              v-model="form.employees"
              name="employees"
              class="form-select form-select-solid"
              data-control="select2"
              data-hide-search="true"
              data-placeholder="Year"
              as="select"
              id="empleados"
            >
              <option></option>
              <template
                v-for="(option, optionIndex) in quantityOptions"
                :key="optionIndex"
              >
                <option :value="option.value">{{ option.show }}</option>
              </template>
            </Field>
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="employees" />
              </div>
            </div>
          </div>
          <!--end::Col-->

          <!--begin::Col-->
          <div class="col-6">
            <!--begin::Label-->
            <label class="required fs-6 fw-bold form-label mb-2">{{
              translate("country")
            }}</label>
            <!--end::Label-->

            <!-- @todo: Country select -->
            <Field
              :disabled="!isEditionEnabled"
              v-model="form.country"
              name="country"
              class="form-select form-select-solid"
              data-control="select2"
              data-hide-search="true"
              data-placeholder="Month"
              as="select"
              id="country"
            >
              <option></option>
              <template
                v-for="(country, countryIndex) in countries"
                :key="countryIndex"
              >
                <option :value="country.name">{{ country.name }}</option>
              </template>
            </Field>
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="country" />
              </div>
            </div>
            <!-- end:: Country select -->
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Col-->
    </div>
    <!--end::Input group-->

    <!--begin::Input group-->
    <div class="d-flex flex-column mb-10">
      <label class="fs-6 fw-bold mb-2">{{
        translate("organizationReason")
      }}</label>

      <textarea
        id="motivo"
        :disabled="!isEditionEnabled"
        v-model="form.motive"
        class="form-control form-control-solid"
        placeholder=""
        name="motive"
      />
    </div>
    <!--end::Input group-->
    <!--begin::Check start up-->
    <div class="d-flex flex-column mb-10">
      <div class="form-check text-left" style="text-align: left !important">
        <input
          type="checkbox"
          class="form-check-input text-left"
          id="startup-checkbox"
          v-model="form.startup"
          name="startup"
          :checked="form.startup"
        />
        <label for="startup-checkbox" class="form-check-label">{{
          translate("organizationStartup")
        }}</label>
      </div>
    </div>
    <!--end::Check start up-->

    <div v-if="isCancelButtonVisible && isEditionEnabled">
      <!-- begin:: Checkbox 2 -->
      <div class="form-check text-left" style="text-align: left !important">
        <input
          class="form-check-input text-left"
          type="checkbox"
          value=""
          id="flexCheckChecked"
          checked
        />
        <label class="form-check-label" for="flexCheckChecked">
          {{ translate("organizationShareStatistic") }}
        </label>
      </div>
      <!-- end:: Checkbox 2 -->
    </div>

    <!--begin::Actions-->
    <div class="text-center mt-15">
      <button
        v-if="isCancelButtonVisible && isEditionEnabled"
        ref="cancelButtonRef"
        id="cancel_button"
        type="reset"
        class="btn btn-light me-3"
        @click.prevent="stopEdition"
      >
        <span class="indicator-label">
          {{ translate("cancel") }}
        </span>
      </button>

      <button
        v-if="isEditionEnabled"
        ref="submitButtonRef"
        type="submit"
        id="kt_modal_new_card_submit"
        class="btn btn-primary"
      >
        <span class="indicator-label">
          {{ translate("save") }}
        </span>
        <span class="indicator-progress">
          {{ translate("pleaseWait") }}
          <span
            class="spinner-border spinner-border-sm align-middle ms-2"
          ></span>
        </span>
      </button>
    </div>
    <!--end::Actions-->
  </Form>
  <!--end::Form-->
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";

const EMPTY_ORGANIZATION = {
  id: null,
  name: "",
  industry: "",
  employees: "",
  visibleMembers: 0,
  country: "",
  motive: "",
  startup: false,
  shareOkrs: false,
  createdAt: new Date()
};

export default defineComponent({
  name: "_app_organization_form",
  components: {
    ErrorMessage,
    Field,
    Form
  },
  setup() {
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };

    const industries = [
      {
        key: "Aeroespacial-y-Defensa",
        name: "Aeroespacial y Defensa"
      },
      {
        key: "Agricultura-y-Alimentacion",
        name: "Agricultura y Alimentación"
      },
      {
        key: "Alta-Tecnologia",
        name: "Alta Tecnología"
      },
      {
        key: "Automotriz",
        name: "Automotriz"
      },
      {
        key: "Bancos-y-Valores",
        name: "Bancos y Valores"
      },
      {
        key: "Call-Center-y-Atencion-al-Cliente",
        name: "Call Center y Atención al Cliente"
      },
      {
        key: "Casas-Inteligentes-y-Domotica",
        name: "Casas Inteligentes y Domótica"
      },
      {
        key: "Ciberseguridad",
        name: "Ciberseguridad"
      },
      {
        key: "Capacitacion-para-Empresas-y-Organizaciones",
        name: "Capacitación para Empresas y Organizaciones"
      },
      {
        key: "Construccion-e-Inmobiliario",
        name: "Construcción e Inmobiliario"
      },
      {
        key: "Consultoria-y-Servicios-Profesionales",
        name: "Consultoría y Servicios Profesionales"
      },
      {
        key: "Contabilidad-y-Finanzas",
        name: "Contabilidad y Finanzas"
      },
      {
        key: "Deportes-y-Bienestar",
        name: "Deportes y Bienestar"
      },
      {
        key: "Distribucion-y-Mayorista",
        name: "Distribución y Mayorista"
      },
      {
        key: "Educacion-y-Formacion",
        name: "ducación y Formación"
      },
      {
        key: "Energia-Quimicos-y-Utilidades",
        name: "Energía, Químicos y Utilidades"
      },
      {
        key: "Energias-Renovables-y-Medio-Ambiente",
        name: "Energías Renovables y Medio Ambiente"
      },
      {
        key: "Fabricacion-e-Industria",
        name: "Fabricación e Industria"
      },
      {
        key: "Gobierno-y-Administracion-Publica",
        name: "Gobierno y Administración Pública"
      },
      {
        key: "Hotel-Viajes-y-Turismo",
        name: "Hotel, Viajes y Turismo"
      },
      {
        key: "Informatica-Software-y-Tecnologia-de-la-Informacion-(IT)",
        name: "Informática, Software y Tecnología de la Información (IT)"
      },
      {
        key: "Investigacion-y-Desarrollo-(I+D)",
        name: "nvestigación y Desarrollo (I+D)"
      },
      {
        key: "Legal-y-Cumplimiento",
        name: "Legal y Cumplimiento"
      },
      {
        key: "Logistica-y-Cadena-de-Suministro",
        name: "ogística y Cadena de Suministro"
      },
      {
        key: "Marketing-Publicidad-y-RRPP",
        name: "Marketing, Publicidad y RRPP"
      },
      {
        key: "Medicina-Farmacia-Biotecnologia-y-Salud",
        name: "Medicina, Farmacia, Biotecnología y Salud"
      },
      {
        key: "Medios-de-Comunicacion-y-Entretenimiento",
        name: "Medios de Comunicación y Entretenimiento"
      },
      {
        key: "Mineria-y-Recursos-Naturales",
        name: "Minería y Recursos Naturales"
      },
      {
        key: "Organizaciones-Sin-Fines-de-Lucro",
        name: "Organizaciones Sin Fines de Lucro"
      },
      {
        key: "Outsourcing-y-Soporte-Técnico",
        name: "Outsourcing y Soporte Técnico"
      },
      {
        key: "Retail-y-Comercio",
        name: "Retail y Comercio"
      },
      {
        key: "Seguros-y-Servicios-Financieros",
        name: "Seguros y Servicios Financieros"
      },
      {
        key: "Telecomunicaciones-y-Redes",
        name: "Telecomunicaciones y Redes"
      },
      {
        key: "Transporte-y-Movilidad",
        name: "Transporte y Movilidad"
      }
    ];
    const countries = [
      {
        id: 1,
        name: "Argentina"
      },
      {
        id: 2,
        name: "Bolivia"
      },
      {
        id: 3,
        name: "Brasil"
      },
      {
        id: 4,
        name: "Chile"
      },
      {
        id: 5,
        name: "Colombia"
      },
      {
        id: 6,
        name: "Costa Rica"
      },
      {
        id: 7,
        name: "Cuba"
      },
      {
        id: 8,
        name: "Ecuador"
      },
      {
        id: 9,
        name: "El Salvador"
      },
      {
        id: 10,
        name: "España"
      },
      {
        id: 11,
        name: "Guatemala"
      },
      {
        id: 12,
        name: "Honduras"
      },
      {
        id: 13,
        name: "México"
      },
      {
        id: 14,
        name: "Nicaragua"
      },
      {
        id: 15,
        name: "Panamá"
      },
      {
        id: 16,
        name: "Paraguay"
      },
      {
        id: 17,
        name: "Perú"
      },
      {
        id: 18,
        name: "Puerto Rico"
      },
      {
        id: 19,
        name: "República Dominicana"
      },
      {
        id: 20,
        name: "Uruguay"
      },
      {
        id: 21,
        name: "Venezuela"
      }
    ];
    const quantityOptions = [
      { value: "1-10", show: "1-10" },
      { value: "11-50", show: "11-50" },
      { value: "51-250", show: "51-250" },
      { value: "251-1.000", show: "251-1.000" },
      { value: "1.001-5.000", show: "1.001-5.000" },
      { value: "Más-de-5.000", show: "Más de 5.000" }
    ];

    const submitOrganization = Yup.object().shape({
      nameOnCard: Yup.string().required(
        translate("organization") + " " + translate("isRequired")
      ),
      industry: Yup.string().required(
        translate("industry") + " " + translate("isRequired")
      ),
      employees: Yup.string().required(
        translate("employees") + " " + translate("isRequired")
      ),
      country: Yup.string().required(
        translate("country") + " " + translate("isRequired")
      )
    });

    return {
      translate,
      submitOrganization,
      industries,
      countries,
      quantityOptions
    };
  },
  props: {
    organization: {
      type: Object,
      default: () => EMPTY_ORGANIZATION
    },
    isEditionEnabled: {
      type: Boolean,
      default: () => false
    },
    isCancelButtonVisible: {
      type: Boolean,
      default: () => true
    }
  },
  watch: {
    organization(organization) {
      this.form = { ...organization };
    }
  },
  data() {
    return {
      form: EMPTY_ORGANIZATION
    };
  },
  methods: {
    stopEdition() {
      this.$emit("onStopEdition");
    },
    save() {
      this.$emit("onSave", this.form);
      setTimeout(() => {
        const modalElement = document.getElementById(
          "_app_organization_form_modal"
        );

        if (modalElement) {
          modalElement.click();
        }
      }, 1000);
    }
  }
});
</script>
