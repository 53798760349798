<template>
  <!--begin::Modal - New Card TOCAR ACA PEDRO-->
  <div
    class="modal fade"
    id="_app_edit_objective_modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header">
          <!--begin::Modal title-->
          <h2>{{ translate("editObjective") }}</h2>
          <!--end::Modal title-->
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body scroll-y mx-5 mx-xl-15 my-5">
          <!--begin::Form-->
          <Form
            id="objective_form"
            class="form"
            @submit="submit"
            :validation-schema="formValidate"
            v-if="
              selectedObjective.owner != NULL && selectedObjective.user.id != ''
            "
          >
            <!--AGREGAGO-->

            <div class="row mb-10" v-if="selectedObjective.parent">
              <div class="col-md-12 fv-row">
                <label class="required fs-6 fw-bold form-label mb-3">
                  {{ translate("objectiveType") }}
                </label>

                <br /><br />

                <!--<div class="btn-group group-custom" role="group" aria-label="">
  
                      <template v-for="u in objectiveTypeList" :key="u.id">
                        <input type="radio" class="btn-check" name="objectiveType" :id="u.id" :value="u.code" v-model="selectedObjective.type" autocomplete="off">
                        <label class="btn btn-outline-primary" :for="u.id">{{ translate(u.name) }}</label>
                      </template>
  
                    </div>-->

                <div class="btn-group group-custom" role="group" aria-label="">
                  <input
                    type="radio"
                    class="btn-check"
                    name="objectiveType"
                    id="1"
                    value="objective"
                    autocomplete="off"
                  />
                  <label
                    :class="
                      selectedObjective.type === 'objective'
                        ? 'btn btn-outline-primary btn-activo'
                        : 'btn btn-outline-primary'
                    "
                    @click.prevent="setType('objective')"
                    for="1"
                    >objective</label
                  >
                  <input
                    type="radio"
                    class="btn-check"
                    name="objectiveType"
                    id="2"
                    value="key_result"
                    autocomplete="off"
                  />
                  <label
                    :class="
                      selectedObjective.type === 'key_result'
                        ? 'btn btn-outline-primary btn-activo'
                        : 'btn btn-outline-primary'
                    "
                    @click.prevent="setType('key_result')"
                    for="2"
                    >Key Result</label
                  >
                </div>

                <Field
                  v-model="selectedObjective.type"
                  name="objectiveType"
                  class="form-select form-select-solid oculto"
                  data-control="select2"
                  data-hide-search="true"
                  data-placeholder="Type"
                  as="select"
                >
                  <option></option>
                  <template v-for="u in objectiveTypeList" :key="u.id">
                    <option :value="u.code">{{ translate(u.name) }}</option>
                  </template>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="objectiveType" />
                  </div>
                </div>
              </div>
            </div>
            <!--FIN AGREGAD-->

            <!--AGREGADO-->
            <div class="row mb-10">
              <div class="col-md-12 fv-row">
                <label
                  class="d-flex align-items-center fs-6 fw-bold form-label mb-2"
                >
                  <span class="required">{{
                    translate("objectiveTitle")
                  }}</span>
                  <i
                    class="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    title="Specify a objective's title"
                  ></i>
                </label>

                <Field
                  type="text"
                  class="form-control form-control-solid"
                  placeholder=""
                  name="title"
                  v-model="selectedObjective.title"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="title" />
                  </div>
                </div>
              </div>
            </div>
            <!--FIN AGREGADO-->

            <!--AGREGADO-->
            <div class="row mb-10">
              <div class="col-md-12 fv-row">
                <label
                  class="d-flex align-items-center fs-6 fw-bold form-label mb-2"
                >
                  <span>{{ translate("description") }}</span>
                  <i
                    class="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    title="Specify a objective's title"
                  ></i>
                </label>

                <el-form-item prop="description">
                  <el-input
                    v-model="selectedObjective.description"
                    type="textarea"
                    rows="3"
                    name="description"
                    :placeholder="translate('objectiveDescriptions')"
                    id="descripcion"
                  />
                </el-form-item>
              </div>
            </div>
            <!--FIN AGREGADO-->

            <h3
              style="margin-bottom: 25px"
              v-if="selectedObjective.type === 'key_result'"
              class="text-center required"
            >
              {{ translate("effectMetrickey") }}
            </h3>
            <h3 style="margin-bottom: 25px" v-else class="text-center">
              {{ translate("effectMetric") }}
            </h3>

            <!--AGREGADO-->
            <div class="row mb-10">
              <!-- begin:: Unit -->
              <div class="col-md-12 fv-row">
                <label
                  :class="
                    selectedObjective.type === 'key_result'
                      ? 'required fs-6 fw-bold form-label mb-2'
                      : 'fs-6 fw-bold form-label mb-2'
                  "
                  >{{ translate("unitOfMeasurement") }}</label
                >

                <div class="agrupa">
                  <Field
                    type="text"
                    v-model="selectedObjective.unit"
                    name="expirationYear"
                    class="form-control form-control-solid"
                    placeholder=""
                  />

                  <div class="dropdown">
                    <button
                      class="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {{ translate("suggestions") }}
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <!--<li><button class="dropdown-item"></button></li>-->
                      <template v-for="u in units" :key="u.id">
                        <li>
                          <button
                            class="dropdown-item"
                            @click.prevent="setSug(u.name)"
                          >
                            {{ translate(u.name) }}
                          </button>
                        </li>
                      </template>
                    </ul>
                  </div>
                </div>

                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="expirationYear" />
                  </div>
                </div>
              </div>
              <!-- end:: Unit -->
            </div>
            <!--FIN AGREGADO-->

            <!--AGREGAGO-->
            <div class="row mb-10">
              <div class="col-md-12 fv-row">
                <div class="row fv-row">
                  <!-- begin:: Start value -->
                  <div
                    :class="{
                      'col-6': selectedObjective.id == null,
                      'col-4': selectedObjective.id != null
                    }"
                  >
                    <label
                      :class="
                        selectedObjective.type === 'key_result'
                          ? 'required fs-6 fw-bold form-label mb-2'
                          : 'fs-6 fw-bold form-label mb-2'
                      "
                    >
                      {{ translate("startValue") }}
                    </label>
                    <Field
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="startValue"
                      data-rules="numeric:1"
                      v-model="selectedObjective.start_value"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="startValue" />
                      </div>
                    </div>
                  </div>
                  <!-- end:: Start value -->

                  <!-- begin:: Target value -->
                  <div
                    :class="{
                      'col-6': selectedObjective.id == null,
                      'col-4': selectedObjective.id != null
                    }"
                  >
                    <label
                      :class="
                        selectedObjective.type === 'key_result'
                          ? 'required fs-6 fw-bold form-label mb-2'
                          : 'fs-6 fw-bold form-label mb-2'
                      "
                      >{{ translate("objectiveValue") }}</label
                    >
                    <Field
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="targetValue"
                      data-rules="numeric:1"
                      v-model="selectedObjective.target_value"
                    />

                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="targetValue" />
                      </div>
                    </div>
                  </div>
                  <!-- end:: Target value -->

                  <!-- begin:: Current value -->
                  <div v-if="selectedObjective.id" class="col-4">
                    <label class="required fs-6 fw-bold form-label mb-2">{{
                      translate("valueNow")
                    }}</label>
                    <Field
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="currentValue"
                      data-rules="numeric:1"
                      v-model="selectedObjective.current_value"
                    />

                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="currentValue" />
                      </div>
                    </div>
                  </div>
                  <!-- end:: Current value -->
                </div>
              </div>
            </div>
            <!--FIN AGREGADO-->
            <!--AGREGADO-->
            <div
              class="row mb-10"
              v-if="selectedObjective.type === 'key_result'"
            >
              <div class="col-md-4 fv-row">
                <label class="required fs-6 fw-bold form-label">{{
                  translate("frequency")
                }}</label>
                <Field
                  v-model="selectedObjective.frequency"
                  name="frequency"
                  class="form-select form-select-solid"
                  data-control="select2"
                  data-hide-search="true"
                  data-placeholder="frequency"
                  style="height: 56%;"
                  as="select"
                >
                  <!-- @todo: Load users -->
                  <option></option>
                  <template
                    v-for="frequency in frequencies"
                    :key="frequency.id"
                  >
                    <option
                      :value="frequency.code"
                      v-bind:selected="
                        frequency.code == 'recurrent' ||
                          (selectedObjective.frequency &&
                            frequency.code == selectedObjective.frequency.code)
                      "
                      >{{ translate(frequency.name) }}</option
                    >
                  </template>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="frequency" />
                  </div>
                </div>
              </div>
              <!--AGREGADO-->
              <div
                class="col-md-4 fv-row"
                v-if="
                  selectedObjective.frequency === 'single' &&
                    selectedObjective.type === 'key_result'
                "
              >
                <label class="fs-6 fw-bold form-label">{{
                  translate("differentiatedStart")
                }}</label>
                <el-date-picker
                  v-model="selectedObjective.differentiatedStart"
                  style="border-radius: 0.475rem;
                        border: 1px solid #E4E6EF;
                        border-color: rgb(217, 217, 217);"
                  type="date"
                  :placeholder="translate('differentiatedStart')"
                >
                </el-date-picker>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="differentiatedStart" />
                  </div>
                </div>
              </div>
              <!--FIN AGREGADO-->
              <!--AGREGADO-->
              <div
                class="col-md-4 fv-row"
                v-if="
                  selectedObjective.frequency === 'single' &&
                    selectedObjective.type === 'key_result'
                "
              >
                <label class="fs-6 fw-bold form-label">{{
                  translate("earlyEnding")
                }}</label>
                <el-date-picker
                  v-model="selectedObjective.earlyEnding"
                  style="border-radius: 0.475rem;
                        border: 1px solid #E4E6EF;
                        border-color: rgb(217, 217, 217);"
                  type="date"
                  :placeholder="translate('earlyEnding')"
                >
                </el-date-picker>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="earlyEnding" />
                  </div>
                </div>
              </div>
              <!--FIN AGREGADO-->
            </div>

            <div class="row mb-10">
              <div class="col-md-12 fv-row">
                <div class="row fv-row">
                  <!-- begin:: fathersGoal -->
                  <div class="col-10">
                    <label class="required fs-6 fw-bold form-label mb-2">{{
                      translate("fathersGoal")
                    }}</label>
                    <Field
                      v-model="selectedObjective.parent"
                      name="fathersGoal"
                      class="form-select form-select-solid"
                      data-control="select2"
                      data-hide-search="true"
                      as="select"
                    >
                      <!-- Conditionally render "withoutParent" option -->
                      <template v-if="selectedObjective.type !== 'key_result'">
                        <option>{{ translate("withoutParent") }}</option>
                      </template>
                      <template
                        v-for="objective in objectives"
                        :key="objective.id"
                      >
                        <RecursiveDropdown
                          :level="level"
                          :element="objective"
                          :selectedObjective="selectedObjective.id"
                        />
                      </template>
                    </Field>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="fathersGoal" />
                      </div>
                    </div>
                  </div>
                  <!-- end:: fathersGoal -->
                </div>
              </div>
            </div>
            <!--AGREGADO-->
            <div class="row mb-10">
              <div class="col-md-12 fv-row">
                <div class="row fv-row">
                  <!-- begin:: User -->
                  <div class="col-5">
                    <label class="required fs-6 fw-bold form-label mb-2">{{
                      translate("assigned")
                    }}</label>
                    <Field
                      v-model="selectedObjective.user.id"
                      name="user"
                      class="form-select form-select-solid"
                      data-control="select2"
                      data-hide-search="true"
                      data-placeholder="User"
                      as="select"
                    >
                      <!-- @todo: Load users -->
                      <option></option>
                      <template v-for="member in members" :key="member.id">
                        <option :value="member.user"
                          >{{ member.name }} {{ member.lastname }}</option
                        >
                      </template>
                    </Field>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="user" />
                      </div>
                    </div>
                  </div>
                  <!-- end:: User -->
                  <!-- begin:: Period -->
                  <div class="col-4">
                    <label class="required fs-6 fw-bold form-label mb-2">{{
                      translate("cycle")
                    }}</label>

                    <Field
                      v-model="selectedObjective.period"
                      name="period"
                      class="form-select form-select-solid"
                      data-control="select2"
                      data-hide-search="true"
                      data-placeholder="Period"
                      as="select"
                    >
                      <option></option>
                      <template v-for="period in periods" :key="period.id">
                        <option :value="period.id">{{ period.name }}</option>
                      </template>
                    </Field>

                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="period" />
                      </div>
                    </div>
                  </div>
                  <!-- end:: Period -->
                  <!--BEGIN: CODE-->
                  <div class="col-3">
                    <label
                      class="
                        d-flex
                        align-items-center
                        fs-6
                        fw-bold
                        form-label
                        mb-2
                      "
                    >
                      <span>{{ translate("code") }}</span>
                    </label>

                    <Field
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="code"
                      v-model="selectedObjective.code"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="code" />
                      </div>
                    </div>
                  </div>
                  <!--END: CODE-->
                </div>
              </div>
            </div>
            <!--AGREGADO-->

            <!--begin::Actions-->
            <div class="text-center mt-3">
              <button
                ref="submitButtonRef"
                type="submit"
                id="objective_submit"
                class="btn btn-primary"
              >
                <span class="indicator-label">
                  {{ translate("save") }}
                </span>
                <span class="indicator-progress">
                  {{ translate("pleaseWait") }}...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
            <!--end::Actions-->
          </Form>

          <!--end::Form-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - New Card-->
</template>

<script>
/* eslint-disable */
import { defineComponent, ref, computed, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import useEmitter from "@/core/plugins/emitter.js";
import * as Yup from "yup";
import { sendMautic } from "@/core/plugins/mautic";
import JwtService from "@/core/services/JwtService";
import RecursiveDropdown from "@/components/app/Objectives/RecursiveDropdown.vue";

const TYPE_OPTIONS = {
  _OBJECTIVE: "objective",
  _KEY_RESULT: "key_result",
};

const KR_TYPE_OPTIONS = {
  _BEHAVIOR: "behavior",
  _RESULT: "result",
};

const EMPTY_OBJECTIVE = {
  id: null,
  frequency: "",
  organization: "",
  title: "",
  description: "",
  owner: null,
  user: null,
  period: "",
  type: "objective",
  parent: "",
  code: "",
  startValue: "",
  targetValue: "",
  currentValue: "",
  unit: "",
  createdAt: "",
  editedAt: "",
  deletedAt: "",
  subObjectives: [],
  differentiatedStart: "",
  earlyEnding: "",
};

export default defineComponent({
  name: "_app_edit_objective_modal",

  components: {
    ErrorMessage,
    RecursiveDropdown,
    Field,
    Form,
  },
  data() {
    return {
      form: EMPTY_OBJECTIVE,
      typeOptions: { ...TYPE_OPTIONS },
      objectiveTypeList: [
        {
          id: 1,
          code: "objective",
          name: "objective",
        },
        {
          id: 2,
          code: "key_result",
          name: "keyResult",
        },
      ],
      units: [
        /*{
          id: 0,
          code: "",
          name: "",
        },*/
        {
          id: 1,
          code: "percentage",
          name: this.translate("percentage"),
        },
        {
          id: 2,
          code: "units",
          name: this.translate("units"),
        },
        {
          id: 3,
          code: "clp",
          name: "CLP",
        },
        {
          id: 4,
          code: "sold_units",
          name: this.translate("soldUnits"),
        },
      ],
      frequencies: [
        {
          id: 1,
          code: "single",
          name: this.translate("single"),
        },
        {
          id: 2,
          code: "recurrent",
          name: this.translate("recurrent"),
        },
      ],
    };
  },

  setup(_, { emit }) {
    const store = useStore();
    const route = useRoute();
    const submitButtonRef = (ref < null) | (HTMLButtonElement > null);
    const { t, te } = useI18n();
    let selectedObjective = ref(EMPTY_OBJECTIVE);
    let level = 0;

    const periods = computed(() => store.getters.getAllCycles);
    const members = computed(() => store.getters.getMembers);
    const objectives = computed(() => store.getters.getObjectives);
    const translate = (text) => {
      return te(text) ? t(text) : text;
    };


    const formValidate = Yup.object().shape({
      title: Yup.string().label("Title")
        .required(translate("objectiveTitle") + " " + translate("isRequired"))
        .max(254, translate("maxTitle")),
      startValue: Yup.string().nullable()
        .label("Start Value")
        .test("is-decimal", "invalid decimal", (value) =>
          (value + "").match(/^-?(\d)*(\.)?([0-9]{1,2})?$/)
        ),
      currentValue: Yup.string().nullable()
        .label("Current Value")
        .test("is-decimal", "invalid decimal", (value) =>
          (value + "").match(/^-?(\d)*(\.)?([0-9]{1,2})?$/)
        ),
      targetValue: Yup.string().nullable()
        .label("Target Value")
        .test("is-decimal", "invalid decimal", (value) =>
          (value + "").match(/^-?(\d)*(\.)?([0-9]{1,2})?$/)
        ),
    });

    watch(
      () => store.getters.getSelectedObjective,
      function() {
        selectedObjective.value = store.getters.getSelectedObjective;
      }
    );

    const setSug = (sug) => {
      selectedObjective.value.unit = sug;
    };

    const setType = (typeToSet) => {
      selectedObjective.value.type = typeToSet;
    };

    const submit = () => {
      const { id } = route.params;

      let inicioDiferenciado = selectedObjective.value.differentiatedStart;

      if (typeof selectedObjective.value.differentiatedStart != "string") {
        if (selectedObjective.value.differentiatedStart != null) {
          inicioDiferenciado = selectedObjective.value.differentiatedStart.toISOString();
        }
      }

      //let finalAnticipado = selectedObjective.value.differentiatedStart;
      let finalAnticipado = selectedObjective.value.earlyEnding;

      if (typeof selectedObjective.value.earlyEnding != "string") {
        if (selectedObjective.value.earlyEnding != null) {
          finalAnticipado = selectedObjective.value.earlyEnding.toISOString();
        }
      }

      const update = {
        id: selectedObjective.value.id,
        organization: id,
        type: selectedObjective.value.type,
        user: selectedObjective.value.user.id,
        title: selectedObjective.value.title,
        description: selectedObjective.value.description ? selectedObjective.value.description : "" ,
        currentValue: selectedObjective.value.current_value
          ? selectedObjective.value.current_value
          : 0,
        startValue: selectedObjective.value.start_value
          ? selectedObjective.value.start_value
          : 0,
        targetValue: selectedObjective.value.target_value
          ? selectedObjective.value.target_value
          : 0,
        differentiatedStart: inicioDiferenciado,
        earlyEnding: finalAnticipado,
        unit: selectedObjective.value.unit,
        period: selectedObjective.value.period,
        parent: Number(selectedObjective.value.parent),
        code: selectedObjective.value.code,
        frequency: selectedObjective.value.frequency,
      };

      if (selectedObjective.value.parent) {
        const updatePos = {
          id: selectedObjective.value.id,
          parent: Number(selectedObjective.value.parent),
          add: false,
          position: level + 1
        };
        emit("reorder", updatePos);
      }
      emit("on-save", update);

      setTimeout(() => {
        const modalElement = document.getElementById(
          "_app_edit_objective_modal"
        );
        if (modalElement) {
          modalElement.click();
        }
        const currentUser = store.getters.currentUser;
        const Cycle = store.getters.getCycle;
        const AllCycles = store.getters.getAllCycles;
        const currentOrganization = store.getters.getOrganization;
        const Objectives = store.getters.getObjectives;
        const Members = store.getters.getMembers;
        let loginLogs;
        const url =
          `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/logs?userId=` +
          this.$store.getters.currentUser.id;
        const currentToken = JwtService.getToken();

        fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": currentToken
          }
        })
          .then(res => res.json())
          .then(data => {
            loginLogs = data.result.logs;
          });
        sendMautic(
          store,
          currentUser,
          Cycle,
          AllCycles,
          currentOrganization,
          Objectives,
          Members,
          loginLogs
        );
      }, 1000);
    };

    return {
      submitButtonRef,
      translate,
      periods,
      selectedObjective,
      submit,
      members,
      objectives,
      setType,
      setSug,
      formValidate,
    };
  },
});
</script>

<style>
.parent-box {
  border: solid 1px #f9f9f9;
  background-color: #f9f9f9;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  font-size: 15px;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.15rem;
  height: 1.15rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns=http://www.w3.org/2000/svg viewBox=0 0 16 16 fill=%23181C32%3e%3cpath fill-rule=evenodd d=M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.15rem;
  transition: transform 0.2s ease-in-out;
}

.btn-activo {
  background-color: #464b6d !important;
  color: #fff !important;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100% !important;
}

.el-input__inner {
  background-color: #f5f8fa;
  border-color: #f5f8fa;
  color: #5e6278;
  transition: color 0.2s ease, background-color 0.2s ease;
  font-size: 1.1rem;
  font-weight: 500;
  width: 100% !important;
}

textarea {
  background-color: #F5F8FA !important;
  border-color: #d9d9d9 !important;
  color: #5E6278 !important;
  transition: color 0.2s ease, background-color 0.2s ease !important;
}
</style>
