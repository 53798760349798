<template>
  <!-- begin:: Accordion -->
  <div class="objective-set">
    <!-- begin:: Objective list -->
    <div v-if="loadedObjectives" class="accordion" id="accordionComponent">
      <!-- begin:: Accordion list item -->
      <Objective @reorder="updatePosition" :objectives="objectives" />
      <!-- end:: Accordion item list -->
      <div class="msjWithoutOrganization" v-if="objectives.length === 0">
        <h2>{{ translate("firstTimeHere") }}</h2>
        <p>{{ translate("withOutOkr") }}</p>

        <a href="/docs" target="_BLANK" class="btn btn-sm btn-primary ms-4">
          <i class="bi bi-file-earmark-text" />
          {{ translate("planificationGuide") }}
        </a>

        <button
          @click="openObjectiveModalOmi()"
          type="button"
          class="btn btn-success btn-sm ms-4"
          data-bs-toggle="modal"
          data-bs-target="#_app_objective_modal_omi"
        >
          <i class="bi bi-plus" />
          {{ translate("createObjective") }}
        </button>
        <div>
          <img
            src="/media/PlanningGuide.png"
            style="padding: 10px"
            alt="e-Learning implementando OKR en 4 pasos"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <ObjectiveSkeleton />
      <ObjectiveSkeleton />
      <ObjectiveSkeleton />
    </div>

    <!-- end::Empty results -->

    <!-- begin:: Objective modal -->
    <ObjectiveFormModal
      @on-save="saveObjective"
      :parent="parent"
      :objective="objective"
      :members="members"
    />
    <!-- end:: Objective modal -->

    <!-- begin:: Objective modal -->
    <ObjectiveFormModalOmi @on-save="saveObjective" :members="members" />
    <!-- end:: Objective modal -->

    <!-- begin:: Objective modal -->
    <EditObjectiveModal
      @on-save="saveObjective"
      :parent="parent"
      :objective="objective"
      :members="members"
    />
    <!-- end:: Objective modal -->

    <!-- begin:: Objective modal -->
    <ObjectiveInfoModal @on-save="saveObjective" />

    <ObjectiveSharedModal
      :objective="objective"
      @fetchObjectives="fetchObjectives()"
    />
    <!-- end:: Objective modal -->

    <!-- begin:: Objective Task Manager modal -->
    <ObjectiveTaskManagerModal
      :objective="objective"
      @fetchObjectives="fetchObjectives()"
    />
    <!-- end:: Objective Task Manager modal -->

    <!-- <MainStructureModal @on-save="saveObjective" :parent="parent" :objective="objective" :members="members" /> -->
    <!-- begin:: Objective Removing modal -->
    <ObjectiveRemovingConfirmerModal @onConfirmRemoving="removeObjective" />
    <!-- end:: Objective Removing modal -->

    <!-- begin:: Objective info modal -->
    <InfoModal />

    <!-- begin:: Objective sharer modal -->
    <ObjectiveSharerManagerModal
      :objective="objective"
      :objectives="objectives"
      :organizations="organizations"
      :my-shares="myShares"
      :waiting-message="waitingMessage"
      @onSearchOrganizations="searchOrganizationsByEmailAndObjective"
      @onSelectOrganization="inviteOrganization"
      @onClearOrganizations="clearOrganizations"
      @onCancelInvitation="cancelObjectiveInvitation"
      @onSend="sendObjectiveInvitation"
      @fetchObjectives="fetchObjectives()"
    />

    <!-- end:: Objective sharer modal -->
  </div>
  <!-- end::Accordion-->
</template>

<script>
/* eslint-disable */
import ObjectiveFormModal from "@/components/app/Objectives/ObjectiveFormModal.vue";
import ObjectiveFormModalOmi from "@/components/app/Objectives/ObjectiveFormModalOmi.vue";
import EditObjectiveModal from "@/components/app/Objectives/EditObjectiveModal.vue";
import ObjectiveTaskManagerModal from "@/components/app/Objectives/ObjectiveTaskManagerModal.vue";
import ObjectiveSharerManagerModal from "@/components/app/Objectives/ObjectiveSharerManagerModal.vue";
import ObjectiveSharedModal from "@/components/app/Objectives/ObjectiveSharedModal.vue";
import Objective from "@/components/app/Objectives/Objective.vue";
import ObjectiveRemovingConfirmerModal from "@/components/app/Objectives/ObjectiveRemovingConfirmerModal.vue";
import JwtService from "@/core/services/JwtService";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { defineComponent, computed } from "vue";
import ObjectiveInfoModal from "@/components/app/Objectives/ObjectiveInfoModal.vue";
import ObjectiveSkeleton from "@/components/app/Objectives/ObjectiveSkeleton.vue";
// import MainStructureModal from "@/components/app/Objectives/Modals/MainStructure.vue";
import InfoModal from "@/components/app/Objectives/Modals/components/Info.vue";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "ObjectiveSet",

  components: {
    ObjectiveFormModal,
    ObjectiveFormModalOmi,
    ObjectiveSharerManagerModal,
    ObjectiveRemovingConfirmerModal,
    ObjectiveTaskManagerModal,
    Objective,
    ObjectiveInfoModal,
    EditObjectiveModal,
    ObjectiveSharedModal,
    ObjectiveSkeleton,
    // MainStructureModal,
    InfoModal
  },

  props: {
    objectiveListSharedByMe: {
      type: Array,
      default: () => [],
    },
  },

  mounted() {
    //

    this.emitter.on("open-objective-modal", this.openObjectiveModal);
    this.emitter.on("open-objective-modal-omi", this.openObjectiveModalOmi);
    // this.emitter.on("on-save", this.saveObjective);
    this.emitter.on("open-objective-info-modal", this.openObjectiveInfoModal);
    this.emitter.on(
      "open-objective-sharer-modal",
      this.openObjectiveSharerModal
    );
    this.emitter.on("open-task-manager-modal", this.openTaskManagerModal);
    this.emitter.on("close-objective-modal", this.closeObjectiveModal);
    //this.emitter.on("updatePosition", this.updatePosition);
    this.emitter.on("openSubObjectiveModal", this.openSubObjectiveModal);
  },

  unmounted() {
    this.emitter.off("open-objective-modal");
    this.emitter.off("open-objective-modal-omi");
    // this.emitter.off("on-save");
    this.emitter.off("open-objective-info-modal");
    this.emitter.off("open-objective-sharer-modal");
    this.emitter.off("open-task-manager-modal");
    this.emitter.off("close-objective-modal");
    this.emitter.off("close-objective-info-modal");
    // this.emitter.off("updatePosition");
    this.emitter.off("openSubObjectiveModal");
  },

  setup() {
    const store = useStore();
    const { t, te } = useI18n();
    const translate = (text) => {
      return te(text) ? t(text) : text;
    };

    const objectives = computed(() => store.getters.getObjectives);
    const loadedObjectives = computed(() => store.getters.getObjectivesLoaded);

    return {
      translate,
      objectives,
      loadedObjectives,
    };
  },

  data() {
    return {
      productionMode: true,
      parent: null,
      objective: {},
      subObjective: null,
      keyResult: null,
      isWaiting: false,
      waitingMessage: "",
      members: [],
      organizations: [],
      myShares: [],
      isRemovingObjective: false,
    };
  },

  watch: {
    objectiveListSharedByMe(value) {
      this.myShares = [...value];
    },
  },

  computed: {
    authenticatedUser() {
      return this.$store.getters.currentUser;
    },
  },

  methods: {
    fetchObjectives() {
      this.$emit("fetchObjectives");
      this.$emit("loadObjectivesSocket");
    },
    openObjectiveSharerModal(objective) {
      this.$store.dispatch(Actions.CHANGE_SELECTED_OBJECTIVE, objective);
      setTimeout(() => {
        this.parent = null;
        this.myShares = [...this.myShares];
      }, 333);
    },

    openObjectiveModal(objective) {
      setTimeout(() => {
        this.parent = null;
        this.objective = objective ? objective : null;
      }, 333);

      // ···
      if (!this.members.length) {
        this.loadMembers();
      }
    },

    openObjectiveModalOmi(objective) {
      setTimeout(() => {
        this.parent = null;
        this.objective = objective ? objective : null;
      }, 333);

      // ···
      if (!this.members.length) {
        this.loadMembers();
      }
    },

    openObjectiveInfoModal(objective) {
      setTimeout(() => {
        this.objective = objective;
      }, 333);
    },

    openSubObjectiveModal(value) {
      const { objective, parent } = value;
      setTimeout(() => {
        this.objective = objective ? objective : null;
        this.parent = parent ? parent : null;
      }, 333);

      // ···
      if (!this.members.length) {
        this.loadMembers();
      }
    },

    openTaskManagerModal(objective) {
      this.$store.dispatch(Actions.CHANGE_SELECTED_OBJECTIVE, objective);
    },

    closeObjectiveModal() {
      this.objective = null;
    },

    saveObjective(objective) {
      const currentToken = JwtService.getToken();
      const creatorUser = this.authenticatedUser;
      const saveMethod = objective.id ? "PUT" : "POST";
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/okrs`;
      const modalCloser = document.getElementById("_app_objective_modal");
      if (saveMethod === "POST") {
        objective.owner = creatorUser ? creatorUser.id : null;
      }

      if (objective.parent == "") {
        objective.type = "objective";
      }

      this.isWaiting = true;
      return fetch(url, {
        method: saveMethod,
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken,
        },
        body: JSON.stringify(objective),
      })
        .then((res) => {
          if (res.status === 401 || res.status === 403) {
            throw new Error("authorizationError");
          } else if (res.status !== 200 && res.status !== 201) {
            throw new Error("generalError");
          } else if (res.status === 200) {
            // this.$emit("loadObjectivesSocket");
            this.$emit("fetchObjectives");
          }
          return res.json();
        })
        .then(() => {
          this.$notify({
            title: this.translate("OK"),
            message: this.translate("objectiveSavedSuccessfully"),
            type: "success",
          });
          /*  $(
            "#_app_objective_modal_omi .btn.btn-sm.btn-icon.btn-active-color-primary"
          ).trigger("click");
          $("#objective_form_omi")[0].reset(); */
          //}, 1000);
        })
        .catch((error) => {
          //
          this.$notify({
            title: this.translate("bad"),
            message: this.translate("savingObjectiveError"),
          });
        })
        .finally(() => {
          setTimeout(function () {
            if (objective.parent) {
              const elto = "#idpadre" + objective.parent;
              if ($(elto).hasClass("collapsed")) {
                $(elto).trigger("click");
              }
            }
          }, 800);
          this.isWaiting = false;
          modalCloser.click();
        });
    },

    updatePosition(obj) {
      const { id, parent, add, position } = obj;
      const { id: organizationId } = this.$route.params;
      const period = this.$store.getters.getCycle;
      const currentToken = JwtService.getToken();
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/okrs/reLocate`;

      const objective = {
        okr: id,
        order: position,
        parent: parent,
        add: add,
        organization: organizationId,
        period: period.id,
      };

      this.isWaiting = true;
      fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken,
        },
        body: JSON.stringify(objective),
      })
        .then((res) => {
          if (res.status === 401 || res.status === 403) {
            throw new Error("authorizationError");
          } else if (res.status !== 200 && res.status !== 201) {
            throw new Error("generalError");
          }
          return res.json();
        })
        .then(() => {
          this.$notify({
            title: this.translate("great"),
            message: this.translate("objectiveSavedSuccessfully"),
            type: "success",
          });
        })
        .catch((error) => {
          //
          this.$notify({
            title: this.translate("bad"),
            message: this.translate("savingObjectiveError"),
            type: "warning",
          });
        })
        .finally(() => {
          // this.$emit("loadObjectivesSocket");
          this.$emit("fetchObjectives");
          this.isWaiting = false;
        });
    },

    loadMembers() {
      const organizationId = this.$route.params.id
        ? this.$route.params.id
        : null;

      if (!organizationId) {
        return this.$notify({
          title: this.translate("important"),
          message: this.translate("noOrganizationHere"),
        });
      }

      const url =
        `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/organizations/members/` +
        organizationId;
      const currentToken = JwtService.getToken();

      this.isWaiting = true;
      return fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken,
        },
      })
        .then((res) => {
          if (res.status === 400) {
            throw new Error("generalError");
          }
          if (res.status === 401 || res.status === 403) {
            setTimeout(() => {
              window.location.href = "/";
            }, 999);
            throw new Error("authorizationError");
          }
          return res.json();
        })
        .then((data) => {
          const { members } = data;
          if (!members || !members.length) {
            return;
          }

          setTimeout(() => {
            this.members = [...members];
          }, 333);
        })
        .catch((error) => {
          //
          this.$notify({
            title: this.translate("thatIsBad"),
            message: this.translate("generalError"),
          });
        })
        .finally(() => {
          this.isWaiting = false;
        });
    },

    searchOrganizationsByEmailAndObjective(searchingData) {
      const { email, objectiveId } = searchingData;
      const currentToken = JwtService.getToken();

      this.organizations = [];
      this.isWaiting = true;
      this.waitingMessage = this.translate("lookingForOrganization") + email;
      setTimeout(() => {
        const url =
          `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/organizations/` +
          email +
          "/owner/" +
          objectiveId;

        return fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": currentToken,
          },
        })
          .then((res) => {
            if (res.status == 404) {
              this.waitingMessage = "";
              return this.$notify({
                title: this.translate("noOrganizations"),
                message:
                  this.translate("noOrganizationsFoundWithEmail") +
                  ": " +
                  email,
                type: "warning",
              });
            }

            return res.json();
          })
          .then((data) => {
            if (!data || !data.organizations) {
              return;
            }
            const { organizations } = data;
            this.isWaiting = false;
            this.waitingMessage = "";
            this.organizations = [...organizations];
            if (!this.organizations || !this.organizations.length) {
              this.$notify({
                title: this.translate("noOrganizations"),
                message:
                  this.translate("noOrganizationsFoundWithEmail") +
                  ": " +
                  email,
                type: "warning",
              });
            }
          })
          .catch((error) => {
            //
            this.isWaiting = false;
            this.waitingMessage = "";
            this.$notify({
              title: this.translate("thatIsBad"),
              message: this.translate("generalError"),
              type: "danger",
            });
          });
      }, 1203);
    },

    inviteOrganization(invitationData) {
      const { organizationId, objectiveId } = invitationData;
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/okrs/share/${objectiveId}/${organizationId}`;
      const currentToken = JwtService.getToken();

      this.isWaiting = true;
      return fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken,
        },
      })
        .then((res) => {
          if (res.status === 401 || res.status === 403) {
            //this.$notify({
            //title: this.translate("bad"),
            //message: this.translate("authorizationError"),
            //type: "danger",
            //});
            //throw new Error("authorizationError");
            throw new Error(this.translate("authorizationError"));
          } else if (res.status === 411) {
            //this.$notify({
            //title: this.translate("bad"),
            //message: this.translate("objectiveReadyShare"),
            //type: "danger",
            //});
            //throw new Error("generalError");
            throw new Error(this.translate("objectiveReadyShare"));
          } else if (res.status !== 200 && res.status !== 201) {
            throw new Error("generalError");
          }
          return res.json();
        })
        .then(() => {
          this.$notify({
            title: this.translate("great"),
            message: this.translate("organizationInvitedSuccessfully"),
            type: "success",
          });
        })
        .catch((error) => {
          //
          this.$notify({
            title: this.translate("bad"),
            message: error,
          });
        })
        .finally(() => {
          this.isWaiting = false;
        });
    },

    cancelObjectiveInvitation(invitationToCancel) {
      alert("@todo: Remove objective invitation");
      // constorganizationId = this.$route.params.id;
      // const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/organizations/member/${memberId}/${organizationId}`;
      // const currentToken = JwtService.getToken();

      // this.isWaiting = true;
      // return fetch(url, {
      //   method: "DELETE",
      //   headers: {
      //     "Content-Type": "application/json",
      //     "x-access-token": currentToken,
      //   },
      // })
      //   .then((res) => {
      //     if (res.status === 401 || res.status === 403) {
      //       setTimeout(() => {
      //         window.location.href = "/";
      //       }, 999);
      //       throw new Error("authorizationError");
      //     } else if (res.status !== 200 && res.status !== 201) {
      //       throw new Error("generalError");
      //     }
      //     return res.json();
      //   })
      //   .then((data) => {
      //     const { result: userData } = data;
      //
      //     this.isWaiting = false;
      //     const memberIndex = this.members.findIndex(
      //       (u) => u.email === invitation.email
      //     );
      //     if (memberIndex > -1) {
      //       this.members.splice(memberIndex, 1);
      //     }
      //     this.isWaiting = false;
      //     this.stopInvitationCancellation();
      //     this.$notify({
      //       title: this.translate("done"),
      //       message: this.translate("invitationCancelledSuccessfully"),
      //     });
      //   })
      //   .catch((error) => {
      //
      //     this.stopInvitationCancellation();
      //     this.$notify({
      //       title: this.translate("bad"),
      //       message: this.translate("invitationCancellationError"),
      //       type: "danger",
      //     });
      //   });
    },

    removeObjective(objectiveToRemove) {
      const { id: objectiveToRemoveId } = objectiveToRemove;
      const { id: organizationId } = this.$route.params;
      const url =
        `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/okrs/` +
        objectiveToRemoveId +
        "/" +
        organizationId;
      const currentToken = JwtService.getToken();
      const modalCloser = document.getElementById(
        "objective_removing_confirmer_modal_closer"
      );

      this.isWaiting = true;
      return fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken,
        },
      })
        .then((res) => {
          if (res.status === 401 || res.status === 403) {
            setTimeout(() => {
              window.location.href = "/";
            }, 999);
            throw new Error("authorizationError");
          }
          return res.json();
        })
        .then(() => {
          // this.emitter.emit("loadObjectivesSocket");
          // this.emitter.emit("fetchObjectives");
          // this.$emit("loadObjectivesSocket");
          this.$emit("fetchObjectives");
          this.$notify({
            title: this.translate("great"),
            message: this.translate("objectiveDeletedSuccessfully"),
            type: "success",
          });
        })
        .catch((error) => {
          //
          this.$notify({
            title: this.translate("thatIsBad"),
            message: this.translate("generalError"),
            type: "danger",
          });
        })
        .finally(() => {
          this.isWaiting = false;
          modalCloser.click();
        });
    },

    clearOrganizations() {
      this.organizations = [];
    },

    sendObjectiveInvitation(objectiveInvitationToSend) {
      //
    },
  },
});
</script>

<style>
.msjWithoutOrganization {
  display: table;
  max-width: 800px;
  background: #fff;
  margin: 25px auto;
  padding: 35px;
  text-align: center;
  border-radius: 10px;
}

.msjWithoutOrganization p {
  font-size: 16px;
  font-weight: 500;
  display: block;
}
</style>
