<template>
  <draggable
    :list="objectives"
    :disabled="isDisabled() || isTypeUser || !isPlanning"
    tag="div"
    item-key="name"
    :group="{ name: 'objectives' }"
    :component-data="getComponentData()"
    @end="onEnd"
    @start="onStar"
  >
    <template #item="{ element }">
      <div
        :objective-id="element.id"
        :objective-old-parent="element.parent"
        class="accordion-item"
      >
        <div
          :id="'collapse' + element.parent"
          :aria-labelledby="'heading' + element.parent"
          :class="[
            element.parent
              ? expand || openAccordion().includes('collapse' + element.parent)
                ? 'accordion-collapse collapse show'
                : 'accordion-collapse collapse'
              : '',
            element.parent == selectedObjective.parent &&
              showAccordion(element.parent),
          ]"
        >
          <div
            :class="[
              selectedObjective.id == element.id
                ? 'highlight-selected-element'
                : '',
              SelectedUser(element.user) && 'backGroundColor',
            ]"
          >
            <div
              :id="'heading' + element.id"
              @click="
                expandedItemId =
                  element.id === expandedItemId &&
                  element.id !== objectives[objectives.length - 1].id
                    ? null
                    : element.id
              "
            >
              <div class="grid-container">
                <div class="grid-item-1">
                  <ObjectiveDescription
                    :activeAccordionGroup="
                      openAccordion().includes('collapse' + element.id)
                    "
                    :SelectedUser="SelectedUser(element.user)"
                    :element="element"
                    :isTypeUser="isTypeUser"
                    :isPlanning="isPlanning"
                    :expand="expand"
                  />
                </div>
                <div class="grid-item-2">
                  <KeyPad
                    :element="element"
                    :isTypeUser="isTypeUser"
                    :isPlanning="isPlanning"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="
                element.id !== objectives[objectives.length - 1].id ||
                element.parent !== null ||
                (element.id === objectives[objectives.length - 1].id &&
                  (element.id === expandedItemId ||
                    (expand && !element.parent)))
              "
              class="separator"
            ></div>
          </div>
          <div :parent="element.id">
            <objective
              class="Objectives-depth"
              @reorder="reorder"
              :objectives="element.subObjectives"
            />
          </div>
        </div>
      </div>
    </template>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
import { computed, watch, ref, watchEffect, nextTick } from "vue";
import { useStore } from "vuex";
import KeyPad from "./KeyPad.vue";
import ObjectiveDescription from "./ObjectiveDescription.vue";

export default {
  name: "Objective",

  components: {
    draggable,
    KeyPad,
    ObjectiveDescription,
  },

  props: {
    objectives: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      expandedItemId: null,
    };
  },

  setup() {
    const store = useStore();
    const expand = computed(() => store.getters.getExpand);

    const selectedObjective = ref(store.getters.getSelectedObjectiveCamelCase);

    const openAccordion = () => {
      const storedValue = localStorage.getItem("activeAccordionGroup");
      if (storedValue !== null && storedValue !== undefined) {
        try {
          return JSON.parse(storedValue);
        } catch (e) {
          console.error("Error al analizar la cadena JSON:", e);
        }
      }
      return [];
    };

    const saveCookies = (id) => {
      const openAccordion = JSON.parse(
        localStorage.getItem("activeAccordionGroup")
      );
      if (openAccordion) {
        if (!openAccordion.includes(id)) {
          openAccordion.push(id);
          localStorage.setItem(
            "activeAccordionGroup",
            JSON.stringify(openAccordion)
          );
        }
      } else {
        localStorage.setItem("activeAccordionGroup", JSON.stringify([id]));
      }
    };

    const showAccordion = (parent) => {
      if (parent) saveCookies("collapse" + parent);
      return parent ? "accordion-collapse collapse show" : "";
    };

    watch(
      () => selectedObjective.value,
      () => {
        if (store.getters.getUpdateFromSocket) {
          return;
        }
        nextTick(() => {
          const highlightedElement = document.querySelector(
            ".highlight-selected-element"
          );
          if (highlightedElement) {
            highlightedElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "center",
            });
          }
        });
      }
    );

    const isTypeUser = computed(() => {
      const currentOrganization = store.getters.getOrganization;
      return (
        (Array.isArray(currentOrganization)
          ? currentOrganization[0]
          : currentOrganization) && currentOrganization.role === "user"
      );
    });

    const SelectedUser = (owner) => {
      const selected = store.getters.getSelectedUser;
      if (selected !== 0) {
        return owner.id == selected;
      }
      return false;
    };

    const isPlanning = computed(() => {
      const currentCycle = store.getters.getCycle;
      return currentCycle && currentCycle.status
        ? currentCycle.status === "planning"
        : false;
    });

    watchEffect(() => {
      selectedObjective.value = store.getters.getSelectedObjectiveCamelCase;
    });

    watch(
      () => store.getters.getChangedObjectBool,
      (changedObjectElement) => {
        if (changedObjectElement.value && changedObjectElement.value.id) {
          const elemento = document.getElementById(
            `heading${changedObjectElement.value.id}`
          );
          if (elemento) {
            elemento.classList.add("highlight-element");
            setTimeout(() => {
              elemento.classList.remove("highlight-element");
            }, 2000);
          }
        }
      }
    );

    return {
      expand,
      isTypeUser,
      isPlanning,
      openAccordion,
      SelectedUser,
      selectedObjective,
      showAccordion,
    };
  },

  methods: {
    isDisabled() {
      const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        // tablet;
        return true;
      }
      if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          ua
        )
      ) {
        // mobile
        return true;
      }
      //desktop
      return false;
    },

    reorder: function (obj) {
      this.$emit("reorder", obj);
    },

    onEnd(evt) {
      const objectiveId = evt.clone.getAttribute("objective-id");
      const objectiveOldParent = evt.clone.getAttribute("objective-old-parent");
      const parentElem = evt.to.parentElement;
      const parent =
        parentElem === null
          ? null
          : evt.to.parentElement.getAttribute("parent");
      //
      const newIndex = evt.newIndex + 1;
      const oldIndex = evt.oldIndex + 1;
      const obj = {
        id: objectiveId,
        parent: parent,
        add: parent === objectiveOldParent ? false : true,
        position: newIndex,
      };
      if (
        (parent === objectiveOldParent && newIndex !== oldIndex) ||
        parent !== objectiveOldParent
      ) {
        //this.emitter.emit("updatePosition", obj);

        this.$emit("reorder", obj);

        // const eltoPadre = document.getElementById("idpadre" + parent);

        // if (eltoPadre.classList.contains("collapsed")) {
        //   setTimeout(function() {
        //     eltoPadre.click();
        //
        //   }, 1300);
        // }
      }
    },

    inputChanged(value) {
      this.activeNames = value;
    },

    getComponentData() {
      return {
        onChange: this.handleChange,
        onInput: this.inputChanged,
        wrap: true,
        value: this.activeNames,
      };
    },

    openObjectiveModal(objective) {
      this.emitter.emit("open-objective-modal", objective);
    },

    openObjectiveSharerModal(objective) {
      this.emitter.emit("open-objective-sharer-modal", objective);
    },

    openSubObjectiveModal(parent) {
      this.emitter.emit("openSubObjectiveModal", {
        objective: null,
        parent: parent,
      });
    },

    startObjectiveRemoving(objective) {
      this.emitter.emit("startObjectiveRemoving", objective);
    },

    openTaskManagerModal(objective) {
      this.emitter.emit("open-task-manager-modal", objective);
    },
  },
};
</script>

<style scoped lang="scss">
.accordion {
  .accordion-item {
    border: none;
  }
}

.highlight-selected-element {
  border: 2px solid #00ff80 !important;
  border-radius: 10px;
}

.highlight-element {
  border: 2px solid #00ff80;
  border-radius: 10px;
}

.highlight {
  animation: pulse 0.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.backGroundColor {
  border: 2px dashed #0059ffd9;
  border-radius: 10px;
}

.separator {
  border-bottom: 1px solid #e9e9e9;
  margin-left: 5px;
  margin-right: 5px;
}

.grid-container {
  display: grid;
  padding: 15px 15px 5px 0px;
  grid-template-columns: 20fr 1fr;
}

.grid-item-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.grid-item-2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* Estilos para pantallas pequeñas */
@media screen and (max-width: 567px) {
  .grid-container {
    display: block;
  }

  .grid-item {
    width: 100%;
  }

  .grid-item-2 {
    justify-content: right;
  }
}

.Objectives-depth {
  padding-left: 20px;
}
</style>
